import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'NotFound',
        component: () => import('../views/404.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/pagina/:pag',
        name: 'Pagina',
        component: () => import('../views/Pagina.vue')
    },
    {
        path: '/produtos/:id/:slug',
        name: 'Produto',
        component: () => import('../views/Produtos.vue')
    },
    {
        path: '/produto/:id/:slug',
        name: 'Detalhes',
        component: () => import('../views/Detalhe.vue')
    },
    {
        path: '/noticia/:id/:slug',
        name: 'Noticia',
        component: () => import('../views/Noticia.vue')
    },
    {
        path: '/noticias/',
        name: 'Noticias',
        component: () => import('../views/Noticias.vue')
    },
    {
        path: '/contato',
        name: 'Contato',
        component: () => import('../views/Contato.vue')
    },
    {
        path: '/galerias/',
        name: 'Galerias',
        component: () => import('../views/Galerias.vue')
    },
    {
        path: '/galeria/:id/:slug',
        name: 'Galeria',
        component: () => import('../views/Galeria.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
