import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import router from './router'
import VueMeta from 'vue-meta'
import VueSilentbox from 'vue-silentbox'
import BackToTop from 'vue-backtotop'

import 'animate.css'

import jQuery from 'jquery';

window.$ = window.jQuery = jQuery;

import 'popper.js';
import 'bootstrap';

Vue.use(require('vue-moment'));
Vue.use(VueSilentbox)
Vue.use(BootstrapVue)
Vue.use(BackToTop)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/owl.carousel.min.css'
import '../src/assets/css/slicknav.css'
import '../src/assets/css/animate.min.css'
import 'swiper/css/swiper.min.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/fontawesome-all.min.css'
import '../src/assets/css/themify-icons.css'
import '../src/assets/css/slick.css'
import '../src/assets/css/nice-select.css'
import '../src/assets/css/estrutura.css'

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
